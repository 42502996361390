import {createContext, useState} from 'react';

export const LayoutContext = createContext({});


export default function LayoutContextProvider({children}){
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    return (
        <LayoutContext.Provider value={{ sidebarOpen, toggleSidebar }}>
            {children}
        </LayoutContext.Provider>
    )
}