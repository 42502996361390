export function getParamsFromLocation(location){
    let locationParams = {};
    if (!location) {
        return locationParams;
    }
    let splitted = location.search.replace('?', '').split('&');
    splitted.forEach((param) => {
        let splitParam = param.split('=');
        locationParams[splitParam[0]] = splitParam[1];
    });
    return locationParams;
}

export function getLocationWithoutParams(location){
    let splitted = location.pathname.split('?');
    return splitted[0]
}

export function getParamsInLocation(location, params) {
    if (!location.pathname){
        location = { pathname: location }
    }
    let { pathname, search } = location;
    let searchParams = [];

    for (var i in params) {
        if (i.length > 0)
            searchParams.push(`${i}=${params[i]}`);
    }
    if (searchParams.length < 1) {
        return `${pathname}`;
    }
    return `${pathname}?${searchParams.join('&')}`;
}