import { createContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Immutable from 'immutable';
import Loader from 'programz/controls/loader';
import Helmet from 'react-helmet';
import { useLocation, useHistory } from 'react-router';
import { getParamsFromLocation, getParamsInLocation } from 'utils/location';

export const ProgramzContext = createContext({});

export default ({ children }) => {
    const [localstate, setLocalState] = useState(Immutable.Map({}));
    const namedContext = useRef({});
    const location = useLocation();
    const history = useHistory();
    const queryParams = getParamsFromLocation(location);

    const setQueryParam = (key, value) => {
        queryParams[key] = value;
        if (!value || (isNaN(value) && value.length < 1)) {
            delete queryParams[key];
        }
        history.push(getParamsInLocation(location, queryParams));
    }

    const deleteQueryParam = (key) => {
        delete queryParams[key];
        history.push(getParamsInLocation(location, queryParams));
    }


    const getCurrentOrganisation = async () => {
        let response = await axios.get('/programz/currentorganisation');
        setLocalState((newstate) => newstate.set('currentorganisation', Immutable.fromJS(response.data)));
    }

    useEffect(() => {
        getCurrentOrganisation();
    }, []);

    return (
        <ProgramzContext.Provider value={{appState: localstate, setLocalState, location: { setQueryParam, deleteQueryParam, queryParams }}}>
            <div data-theme={localstate.getIn(['currentorganisation', 'colourscheme'])}>
                {localstate.get('currentorganisation') && children}
                {!localstate.get('currentorganisation') && <Loader />}
            </div>
            <Helmet>
                <title>{localstate.getIn(['currentorganisation', 'name'], 'Programz')}</title>
            </Helmet>
        </ProgramzContext.Provider>
    )
}