import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import 'styles.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import AuthorizationProvider from 'context/authorization';
import LayoutProvider from 'context/layout';
import ProgramzProvider, { ProgramzContext } from 'programz/context';
import moment from 'moment';
//TODO: Later de locale via configuratie in kunnen stellen.
moment.locale('nl');

const App = React.lazy(() => import('./app'));
const Login = React.lazy(() => import('./login'));
const IntakeForm = React.lazy(() => import('./intake-form'));
const EvaluationForm = React.lazy(() => import('./evaluation-form'));
const Programz = React.lazy(() => import('./programz'));
const Creaflow = React.lazy(() => import('./creaflow'));

const RegisteredApplications = {
    'zorgapp': App,
    'programz': Programz,
    'creaflow': Creaflow
}

const Bootstrap = () => {

    const programzContext = React.useContext(ProgramzContext);

    return (
        <React.Suspense fallback={<h1>Loading...</h1>}>

            <Switch>
                <Route path="/forms/intake/:careid/:validationcode" component={IntakeForm} />
                <Route path="/evaluations" component={EvaluationForm} />
                <Route render={() => {
                    let host = window.location.host;
                    if (host.startsWith('portaal') || host.startsWith('app')) {
                        window.location = "https://www.kraamzorgmama.nl";
                        return (<data></data>)
                    }
                    return (
                        <LayoutProvider>
                            <AuthorizationProvider>
                                <Switch>
                                    <Route path="/login" component={Login} />
                                    <Route path="/" render={() => {
                                        let selectedapp = "zorgapp";
                                        if (host.startsWith('programz')) {
                                            selectedapp = "programz";
                                        }
                                        if (host.indexOf('creaflow') >= 0) {
                                            selectedapp = 'creaflow';
                                        }
                                        let AppMain = RegisteredApplications[selectedapp]

                                        if (selectedapp == 'programz') {
                                            return <AppMain />
                                        }
                                        return (

                                            <AppMain />

                                        )
                                    }} />
                                </Switch>
                            </AuthorizationProvider>
                        </LayoutProvider>
                    )
                }} />

            </Switch>
        </React.Suspense>
    )
}

ReactDOM.render(<Router><ProgramzProvider><Bootstrap /></ProgramzProvider></Router >, document.getElementById('root'));