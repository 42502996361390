import React, { createContext, useState, useEffect } from 'react';
import Axios from 'axios';
import Immutable from 'immutable';
import { useHistory } from 'react-router-dom';

export const AuthorizationContext = createContext({});

let redirectlocation = undefined;

const setRedirectLocation = (location) => {
    redirectlocation = location;
}

export default ({ children }) => {
    let [currentuser, setUser] = useState(undefined);
    let [error, setError] = useState(undefined);
    let [loading, setLoading] = useState("Loading");
    let [sessionupdate, setSessionUpdate] = useState(Date.now());
    let history = useHistory();
    let [authorizationconfiguration, setAuthorizationConfiguration] = useState(undefined);

    useEffect(() => {
        Axios.get('/authorizations.list').then((response) => {
            setAuthorizationConfiguration(Immutable.fromJS(response.data));
        });
    }, []);

    useEffect(() => {
        let mounted = true;
        setLoading("Loading authentication");
        //TODO: Deliberate delay, need to be removed!
        getCurrentUser().then((user) => {
            mounted && setUser(Immutable.fromJS(user));
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            if (err && err.response && err.response.status === 403) {

                return;
            }
            setError("An unexpected error occurred while fetching the current user. Please contact the administrator.");
        });
        return () => {
            mounted = false;
        }
    }, [sessionupdate]);

    const loginByGoogleToken = async (token) => {
        debugger;
        await Axios.post('/api/v1/auth/google', { token });
        return setSessionUpdate(Date.now());
    }

    const redirect = () => {
        if (redirectlocation) {
            history.push(redirectlocation);
        }
        else {
            history.push('/');
        }
    }

    const confirmLogin = () => {
        return setSessionUpdate(Date.now());
    }

    const getCurrentUser = async () => {
        let response = await Axios.get('/auth/getCurrentUser');
        return response.data;
    }

    const login = async (username, password) => {
        setLoading("Please wait while we are authenticating you.");
        Axios.post('/auth/login', { username, password }).then((response) => {
            setUser(Immutable.fromJS(response.data));
            setLoading(false);
        }).catch((err) => {
            setError("The given username and/or password are not correct. Please try again!");
            setLoading(false);
        });
    }

    const logout = async () => {
        await Axios.get('/auth/logout');
        setUser(undefined);
    }

    const isAuthorized = (authorizations) => {
        if (!currentuser) {
            return false;
        }
        if (currentuser.get('superadmin')) {
            return true;
        }
        if (!authorizations) {
            return true;
        }
        if (authorizations === 'super-admin') {
            return false;
        }
        if (Array.isArray(authorizations)) {
            let hasAuthorization = false;

            authorizations.forEach((authorization) => {
                if (currentuser.get('authorizations', []).indexOf(authorization) >= 0) {
                    hasAuthorization = true;
                }
            });

            if (hasAuthorization) {
                return true;
            }
        }
        if (!Array.isArray(authorizations)) {
            if (currentuser.get('authorizations', []).indexOf(authorizations) >= 0) {
                return true;
            }
        }
        return false;
    }

    const hasPermissions = (objectid, permissions) => {
        if (currentuser.get('superadmin')) {
            return true;
        }
        if (currentuser.getIn(['owns', objectid])) {
            return true;
        }
        if (!permissions && currentuser.getIn(['permissions', objectid])) {
            return true;
        }
        if (currentuser.getIn(['permissions', objectid, permissions])) {
            return true;
        }
        return false;
    }

    return (
        <AuthorizationContext.Provider value={{
            currentuser,
            getCurrentUser,
            login,
            logout,
            isAuthorized,
            hasPermissions,
            loginByGoogleToken,
            setRedirectLocation,
            redirect: redirect,
            error: error,
            loading: loading,
            authorizationconfiguration,
            externalRedirect(url) {
                window.location = url;
            },
            confirmLogin
        }}>
            {children}
        </AuthorizationContext.Provider>
    )
}