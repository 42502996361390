import React from 'react';

const Loader = ({message}) => {
    return (
        <div className="absolute top-0 right-0 left-0 bottom-0 w-screen h-screen bg-system-800 items-center pt-4 z-50 px-6 py-4">
            <div className="flex flex-row">
                <div className="flex-1"></div>
                <h1 className="text-white text-5xl mt-[15%]">{message || "Please wait"}</h1>
                <div className="flex-1"></div>
            </div>
            <div className="flex flex-row mt-10">
                <div className="flex-1"></div>
                <div className="">
                    <img src="/loader.svg" alt="Please wait" className="w-48" />
                </div>
                <div className="flex-1"></div>
            </div>
        </div>
    )
}

export default Loader;